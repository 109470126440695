import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <Hero
          title="colenio classroom"
          subtitle="Unser Angebot zur Schulung von Mitarbeitern im Datenschutz"
        />
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Angebot anfordern</h1>
              <form
                name="classroom-contact"
                method="post"
                action="/classroom/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input
                  type="hidden"
                  name="form-name"
                  value="classroom-contact"
                />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>

                <label class="label">Ihre Kontaktdaten</label>
                <div class="field-body">
                  <div className="field">
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type={'text'}
                        name={'company'}
                        placeholder="Name des Unternehmens"
                        onChange={this.handleChange}
                        id={'company'}
                        required={true}
                      />
                      <span class="icon is-small is-left">
                        <i class="fas fa-building"></i>
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type={'text'}
                        name={'name'}
                        placeholder="Name des Ansprechparters"
                        onChange={this.handleChange}
                        id={'name'}
                        required={true}
                      />
                      <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'email'}>
                    Email
                  </label>
                  <div className="control has-icons-left">
                    <input
                      className="input"
                      type={'email'}
                      name={'email'}
                      placeholder="Ihre Email-Adresse"
                      onChange={this.handleChange}
                      id={'email'}
                      required={true}
                    />
                    <span class="icon is-small is-left">
                      <i class="fas fa-envelope"></i>
                    </span>
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'employees'}>
                    Anzahl Mitarbeiter
                  </label>
                  <div className="control has-icons-left">
                    <input
                      className="input"
                      type={'number'}
                      name={'employees'}
                      min={'1'}
                      max={'500'}
                      step={'1'}
                      onChange={this.handleChange}
                      id={'employees'}
                      required={true}
                    />
                    <span class="icon is-small is-left">
                      <i class="fas fa-users"></i>
                    </span>
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'message'}>
                    Ihre Nachricht
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={'message'}
                      placeholder="Sie können uns gerne noch eine Nachricht schreiben. Für das Angebot ist dies jedoch nicht notwendig."
                      onChange={this.handleChange}
                      id={'message'}
                      required={false}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <label className="checkbox">
                      <input
                        className="checkbox"
                        type={'checkbox'}
                        name={'terms-notice'}
                        onChange={this.handleChange}
                        id={'terms-notice'}
                        required={true}
                      />
                      Ich habe die{' '}
                      <a href="/privacy_statement" target="_blank">
                        Datenschutzbestimmungen
                      </a>{' '}
                      zur Kenntnis genommen.
                    </label>
                  </div>
                </div>
                <div className="field">
                  <button className="button is-primary" type="submit">
                    Angebot anfordern
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
